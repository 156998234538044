.form-search {
  position: relative;

  .dropdown-search-input {
    min-width: 100%;
    background-color: #F3F6F9;
    position: absolute;
    z-index: 1000;
    top: 42px;
    border: 1px solid #ECF0F3;

    .drop-content {

      max-height: 270px;
      overflow-y: auto;

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;
        border-bottom: 1px solid #ECF0F3;

        &:hover {
          cursor: pointer;
          background-color: #fff;

        }

        &:first-child {
          border-bottom: none;
        }

        span {
          margin-left: 16px;
        }

        & [class*="col-"] {
          padding: 0px;
        }

      }

      .loader-drop {
        position: absolute;
        right: 14px;
        top: 8px;
      }

      @media (max-height: 600px) {
        max-height: 90px;
      }
    }

    .drop-footer {
      border-top: 1px solid #ECF0F3;
      padding: 12px;
      display: flex;
      justify-content: center;
    }
  }
}