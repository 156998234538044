@import "itemHistory";
@import "DetailOrder";
@import "tagRow";

.history-card {
  &.card {

    .card-body {
      padding: 0px;
    }
  }
}


.row-history {
  display: flex;

  .col-filters {
    flex-basis: 15%;
    min-width: 240px;
    margin-right: 0px;
    margin-top: 0px;
    box-shadow: 6px 0 9px -7px rgba(0, 0, 0, 0.15);
    z-index: 2;

    .form-group {
      margin-bottom: 0px;
      .shared-checkbox {
        margin-bottom: 10px;
      }
    }

    .card {
      &.card-custom {
        & .card-body {
          padding: 15px;
        }
      }
    }
  }

  .col-history {
    width: 100%;
    z-index: 1;

    .row-history-content {
      display: flex;

      .col-history-list {
        flex-basis: 20%;
        min-width: 460px;
        box-shadow: 6px 0 9px -7px rgba(0, 0, 0, 0.15);
        z-index: 2;
      }

      .col-history-item {
        width: 100%;
        z-index: 1;

        .content-product-item {
          background: none !important;
        }

      }
    }
  }


  @media (max-width: 1024px) {
    flex-direction: column;

    .col-filters {
      font-size: 80%;
      order: 2;
      max-height: 300px;
      overflow: auto;
      margin-top: 20px;
      margin-right: 0px;

      /*  .card {
          &.card-custom {
              & .card-body {
                  padding: 15px;
              }
          }
      } */
    }

    .col-history {
      .row-history-content {

        .col-history-list {
          min-width: 300px;
          font-size: 80%;

        }

        .col-history {
          order: 1;
        }
      }
    }
  }
}