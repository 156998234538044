@import 'variables';

.shared-itemMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
  position: relative;

  &.active {
    cursor: initial;
    color: $sh-itemMenu-active-color;
  }

  & .icon-content {
    position: relative;

    & .notify {
      width: 18px;
      height: 18px;
      background-color: $success;
      border-radius: 50%;
      position: absolute;
      top: -6px;
      right: -6px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      padding: 2px;
      font-weight: 600;
      font-family: $font-family-base;
    }
  }
}