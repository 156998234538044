.headerDetailOrder {
  .row-detail-order {
    .title-page.header-detail {
      margin-top: 0px;
      padding-bottom: 0px !important;
    }
  }

  .detail {
    display: flex;
    align-items: center;

    span {
      margin-right: 24px;
      white-space: nowrap;
    }

    svg {
      color: #E8C451;

      margin-right: 8px;
      font-size: 18px;
    }

  }

  small {
    color: $dark;
    font-size: 12px;
  }

  .time-row {
    display: flex;
    flex-wrap: wrap;
    // margin-bottom: 12px;

  }

  .label-value {
    margin-bottom: 0px !important;
  }

  .order-date {
    margin-left: auto;
    font-family: var(--main-font-family-sans-serif-light);
  }

  .section-order {
    .order-detail-row {
      // background-color: #f1f5f7;
      //padding: 8px 16px;
    }
  }
}

section {
  margin-top: 16px;

  .title-section {
    margin-bottom: 0px;
    font-family: var(--main-font-family-sans-serif-regular);
    font-size: 18px;

    display: flex;
    align-items: center;

    border-bottom: 1px solid transparentize($border-color, 0.5);
    padding-bottom: 2px;
    margin-bottom: 8px;

    //margin-bottom: 8px;
  }
}
