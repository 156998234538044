.item-row {
  .tag-item {
    display: inline-flex;
    border: 1px solid $primary;
    padding: 4px;
    padding-right: 16px;
    padding-left: 8px;
    position: relative;
    margin-right: 12px;
    margin-bottom: 8px;

    .shared-icon {
      position: absolute;
      right: -6px;
    }
  }

  &.read {
    display: flex;
    flex-direction: column;

    .tag-item {
      display: inline;
      padding-right: 8px;
    }
  }
}