@import 'variables';

.shared-wizardStep {
  display: flex;
  gap: 12px;
  align-items: baseline;
  //height: 42px;
  //border-bottom: 1px solid $border-color;
  color: $color-shared-wizardStep;
  background-color: #fff;
  font-size: 24px;
  font-family: var(--main-font-family-sans-serif-light);
  font-weight: 600;
.icon-step{

}
  &.active {
    color: $color-active-shared-wizardStep;
    opacity: .8;

    &.current {
      font-family: var(--main-font-family-sans-serif-regular);
      opacity: 1;
    }
  }

}

