.card-patient {
  background-color: $gray-100;
  padding: 20px;

  /*     .icon-block {
      padding: 16px;
      width: 72px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
  }
*/
  svg {
    color: #E8C451;
    font-size: 18px;
  }

  .detail,
  .residence,
  .domicile {
    display: flex;
    align-items: center;

    .heading-4 {
      margin-right: 16px;
    }

    span {
      margin-right: 24px;

      &.caption {
        margin-right: 8px;
      }
    }

    svg {
      margin-right: 8px;
    }
  }

}

.detail-patient {
  display: flex;
  gap: 24px;
  row-gap: 12px;
  flex-direction: column;

  .label-icon {
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: normal;
  }
}

.bg-img-patient-page {
  background-size: 22%;
  background-repeat: no-repeat;
  background-position: center 75%;
  @media (max-height: 830px) {
    background-size: 18%;
  }
  @media (max-height: 780px) {
    background-size: 14%;
  }
  @media (max-height: 730px) {
    background-size: 10%;
  }
  @media (max-height: 690px) {
    background-size: 8%;
  }
}
