.table-hover tbody tr:hover {
    background-color: #f9f9f9 !important;
    cursor: pointer !important;
}

.checkbox.checkbox-single>span {
    top: 2px !important;
}

.table th, .table td{
    padding: 0.4rem !important;
}

.svg-icon.svg-icon-lg-table svg{
    width: 35px !important;
    height: 35px !important;
}


