@import 'variables';

.shared-itemHistory {
  min-height: 115px;
  padding: 8px;
  gap: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  border: none;
  border-bottom: 1px solid $border-color;

  &.active {
    cursor: default;
    background: linear-gradient(90deg, #b8ccd680, #b9cdd740),
  }

  .time-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }


  small {
    color: $dark;
    font-size: 12px;
  }

  .box-wrap {
    display: flex;

    align-items: baseline;
    flex-wrap: wrap;
    gap: 6px;
    row-gap: 0px;
  }

  .user-row {
    line-height: normal;
    margin-bottom: 3px;
    //padding: 4px 0px;
  }

  .practice-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}
