@import "variables";

.shared-button.sh-primary {
&:disabled{
  cursor: auto;
}
/*     &.error {
        background-color: #c00000;
        border: none;

        &.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text),
        .btn.btn-primary:not(:disabled):not(.disabled).active,
        .show>.btn.btn-primary.dropdown-toggle,
        .show .btn.btn-primary.btn-dropdown {
            background-color: #8b1a25;
        }

        &.btn-primary:hover:not(.btn-text) {
            background-color: #8b1a25;
        }
    }

    &.danger {
        background-color: #f05800;
        border: none;

        &.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text),
        .btn.btn-primary:not(:disabled):not(.disabled).active,
        .show>.btn.btn-primary.dropdown-toggle,
        .show .btn.btn-primary.btn-dropdown {
            background-color: #bb7524;
        }

        &.btn-primary:hover:not(.btn-text) {
            background-color: #bb7524;
        }

    } */
}