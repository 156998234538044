@import "variables";

.heading-3 {
  font-size: $fontSizeHeading3;
  color: $colorHeading3;
  line-height: $lineHeightHeading3;
  margin-bottom: 8px;
  font-weight: bold;
  font-family: $font-family-base-reg;

  &.reverse {
    color: $colorHeading3Reverse;
  }

  &.link {
    color: $colorHeading3Link;
  }
}