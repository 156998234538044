@import "variables";
.shared-error {
  color: $colorError;
  font-size: $fontSizeError;
  margin-top: $marginTopError;
  margin-bottom: $marginBottomError;
  min-height: $heightError;
  &.success {
    color: $colorSuccessError;
  }
}
