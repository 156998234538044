.form-group {
  margin-bottom: 0px;

  h6.fw-bolder, label {
    font-weight: 100 !important;
    font-size: 16px;
    color: $dark;
  }

  .form-control {
    padding-left: 8px;
    padding-right: 8px;
    height: auto;

    &.customPickerDisplay {
      font-size: 14px;

      .react-datepicker__close-icon {
        padding: 0px;

      }
    }

    &:disabled {
      opacity: 0.5;
    }

    &.is-valid {
      background-image: none;
    }

    &.is-invalid {
      background-image: none;
    }
  }

  .valid-feedback {
    display: none;
  }

  .invalid-feedback {
    color: $error;
  }
}

.css-2b097c-container {
  .css-4j2rif-control {
    border: 1px solid $input-border-color;

    .css-1hb7zxy-IndicatorsContainer {
      .css-1okebmr-indicatorSeparator {
        display: none;
      }
    }
  }
}

.ae-sh-library .input-group-text {
  background-color: transparent;
  // border-right: none;
  border-color: $input-border-color;
  padding: 8px;

  & + input {
    border-left: 0px;

    &:focus {
      border-color: $input-border-color;
    }
  }
}


.filters-structure {
  .form-group {
    min-height: auto;
    margin-bottom: 12px !important;
  }
}

.templates-wrap {
  form {
    .row-form {
      margin-bottom: 16px;
    }
  }
}