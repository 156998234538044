.sh {
  &.navbar {
    display: flex;
    flex-direction: column;
    box-shadow: $box-shadow;
    background: $gradient-light;
    height: auto;
    justify-content: flex-start;
    padding: 0px;

    .info-nav {
      visibility: visible
    }

    .navbar-brand {
      padding: 0px;
      display: flex;
      align-items: center;
    }

    .nav-right {
      display: flex;
      align-items: center;

      .item-profile {
        display: flex;
        align-items: center;

        .user-label {
          font-family: var(--main-font-family-sans-serif-regular);
          margin-right: 20px;
          text-align: right;
          cursor: pointer;
        }
      }
    }

    .nav-content {
      display: flex;
      width: 100%;
      align-content: center;
    }

    .info-nav {
      background: $gradient-light;
      padding-top: 12px;
    }

    &.var-height {
      height: 128px;
    }

    &.sticky-subHeader {
      //height: 128px;
      height: 0;

      .info-nav {
        visibility: hidden;
      }

      .sub-navbar {
        position: fixed;
        z-index: 10001;
        top: 0px;
        background: $gradient-light;
        box-shadow: $box-shadow;
        padding-top: 14px;
        padding-bottom: 0px;
      }
    }

    .sub-navbar {
      padding: 14px 0px;
      padding-bottom: 0;
      position: relative;
      justify-content: flex-start;
      background: $gradient-light;
      box-shadow: 0 7px 6px 0px rgb(0 47 90 / 20%);

      .icon-expand {
        position: absolute;
        bottom: -2px;
        left: calc(50% - 8px);
        cursor: pointer;
      }
    }

    @media (max-width: $lg-device) {
      .nav-content {
        flex-direction: column;

        .left {
          order: 2
        }

        .right {
          order: 1;
          margin-top: 8px;
          display: flex;
          justify-content: flex-end;
        }

        .center {
          order: 3
        }
      }
      &.var-height {
        height: auto;
      }
    }
  }

  .back-btn {
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    max-width: 90px;
    min-width: 90px;
  }

  .nav-items-menu {
    display: flex;

    .nav-item-menu {
      font-family: var(--main-font-family-sans-serif-regular);
      font-weight: normal;
      font-size: 18px;
      color: $link-color;
      margin: 0px 24px;
      padding: 10px 8px;
      display: inline-block;

      .nav-link {
        &.dropdown-toggle {
          &:after {
            vertical-align: 0;
            //border-top: 0.25em solid;
            border-right: 0.25em solid transparent;
            border-bottom: 0;
            border-left: 0.25em solid transparent;
          }
        }
      }


      &.active {
        a {
          color: $primary-dark;
          font-weight: bold;
        }
      }

      &.dropdown-opened {
        background: $light-blue;
      }

      .dropdown-menu {
        margin-top: 4px;
        margin-left: -8px;
        border: none;
        background: $light-blue;
        padding: 16px;

        .dropdown-item {
          color: $primary;
          padding: 10px 4px;
          font-family: var(--main-font-family-sans-serif-light);
          cursor: pointer;
          font-weight: normal;
          &.active, &:active {
            background: transparent;
            color: $primary-dark;
            font-family: $font-family-sans-serif-regular;
          }
          &:hover{
            background: transparent;
          }
        }
      }
    }


    @media (max-width: $lg-device) {
      flex-direction: column;
      margin-top: 16px;
      .nav-item-menu {
        margin: 0px 0px;
        padding: 8px 8px;

        .dropdown-menu {
          position: relative;
          margin-left: 0px;
          padding: 12px 6px;

          .dropdown-item {
            padding: 0;
          }
        }
      }
    }
  }


}

.sidebar {
  z-index: 10001;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -395px;
  left: auto;
  overflow-y: auto;
  width: 375px;
  background: #ffffff;
  box-shadow: $box-shadow;
  padding: 2em;
  transition: left 0.3s ease, right 0.3s ease;

  &.in {
    right: 0;
    transition: left 0.3s ease, right 0.3s ease;
  }

  .sidebar-header {
    display: block;
    text-align: end;
  }

  .sidebar-body {
    height: calc(100% - 24px);
  }

}

