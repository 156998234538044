@import 'variables';

.shared-labelValue {
  display: flex;

  .label-opt {
    //font-weight: bold;
  }
  .value-opt {
  }
}