//
// Border utilities
//

// Border radius reset
.rounded-top-0 {
    border-top-start-radius: 0 !important;
    border-top-end-radius: 0 !important;
}

.rounded-bottom-0 {
    border-bottom-start-radius: 0 !important;
    border-bottom-end-radius: 0 !important;
}

.rounded-start-0 {
    border-top-start-radius: 0 !important;
    border-bottom-start-radius: 0 !important;
}

.rounded-end-0 {
    border-top-end-radius: 0 !important;
    border-bottom-end-radius: 0 !important;
}

// Border transparent
.border-transparent {
    border-color: transparent !important;
}

// Responsive borders
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .border-x#{$infix}              { border-left: $border-width solid $border-color !important; border-end: $border-width solid $border-color !important; }
        .border-y#{$infix}              { border-top: $border-width solid $border-color !important; border-bottom: $border-width solid $border-color !important; }

        .border-x#{$infix}-0            { border-left: 0 !important; border-end: 0 !important; }
        .border-y#{$infix}-0            { border-top: 0 !important; border-bottom: 0 !important; }

        @if $infix != '' {
            .border#{$infix}            { border: $border-width solid $border-color !important; }
            .border-top#{$infix}        { border-top: $border-width solid $border-color !important; }
            .border-end#{$infix}      { border-end: $border-width solid $border-color !important; }
            .border-bottom#{$infix}     { border-bottom: $border-width solid $border-color !important; }
            .border-left#{$infix}       { border-left: $border-width solid $border-color !important; }

            .border#{$infix}-0          { border: 0 !important; }
            .border-top#{$infix}-0      { border-top: 0 !important; }
            .border-end#{$infix}-0    { border-right: 0 !important; }
            .border-bottom#{$infix}-0   { border-bottom: 0 !important; }
            .border-left#{$infix}-0     { border-left: 0 !important; }
        }

        @for $i from 1 through 5 {
            .border#{$infix}-#{$i}{
                border-width: #{$i}px !important;
            }
        }
    }
}

// Border theme colors
@each $color, $value in $theme-colors {
    .border-#{$color}           { border-color: $value !important; }
    .border-top-#{$color}       { border-top-color: $value !important; }
    .border-end-#{$color}     { border-end-color: $value !important; }
    .border-bottom-#{$color}    { border-bottom-color: $value !important; }
    .border-start-#{$color}      { border-start-color: $value !important; }
}

// Border theme light colors
@each $color, $value in $theme-light-colors {
    .border-light-#{$color}           { border-color: $value !important; }
    .border-top-light-#{$color}       { border-top-color: $value !important; }
    .border-end-light-#{$color}     { border-end-color: $value !important; }
    .border-bottom-light-#{$color}    { border-bottom-color: $value !important; }
    .border-start-light-#{$color}      { border-start-color: $value !important; }
}

// Border Radiuses
// SM Size
.rounded-top-sm {
    border-top-start-radius: $border-radius-sm !important;
    border-top-end-radius: $border-radius-sm !important;
}

.rounded-top-start-sm {
    border-top-start-radius: $border-radius-sm !important;
}

.rounded-top-end-sm {
    border-top-end-radius: $border-radius-sm !important;
}

.rounded-bottom-sm {
    border-bottom-start-radius: $border-radius-sm !important;
    border-bottom-end-radius: $border-radius-sm !important;
}

.rounded-bottom-start-sm {
    border-bottom-start-radius: $border-radius-sm !important;
}

.rounded-bottom-end-sm {
    border-bottom-end-radius: $border-radius-sm !important;
}

// Default Size
.rounded-top {
    border-top-start-radius: $border-radius !important;
    border-top-end-radius: $border-radius !important;
}

.rounded-top-left {
    border-top-start-radius: $border-radius !important;
}

.rounded-top-right {
    border-top-end-radius: $border-radius !important;
}

.rounded-bottom {
    border-bottom-start-radius: $border-radius !important;
    border-bottom-end-radius: $border-radius !important;
}

.rounded-bottom-left {
    border-bottom-start-radius: $border-radius !important;
}

.rounded-bottom-right {
    border-bottom-end-radius: $border-radius !important;
}

// Xl Size
.rounded-xl {
    border-radius: $border-radius-xl !important;
}

.rounded-top-xl {
    border-top-start-radius: $border-radius-xl !important;
    border-top-end-radius: $border-radius-xl !important;
}

.rounded-top-start-xl {
    border-top-start-radius: $border-radius-xl !important;
}

.rounded-top-end-xl {
    border-top-end-radius: $border-radius-xl !important;
}

.rounded-bottom-xl {
    border-bottom-start-radius: $border-radius-xl !important;
    border-bottom-end-radius: $border-radius-xl !important;
}

.rounded-bottom-start-xl {
    border-bottom-start-radius: $border-radius-xl !important;
}

.rounded-bottom-end-xl {
    border-bottom-end-radius: $border-radius-xl !important;
}

// Box shadow
.shadow-xs {
    box-shadow: $box-shadow-xs;
}
