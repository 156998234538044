@import "variables";

.heading-4 {
  font-size: $fontSizeHeading4;
  color: $colorHeading4;
  line-height: $lineHeightHeading4;
  margin-bottom: 8px;
  font-weight: normal;
  font-family: $font-family-base-reg;

  &.reverse {
    color: $colorHeading4Reverse;
  }

  &.dark {
    color: $colorHeading4-dark;
  }
  &.gray {
    color: rgb(98, 106, 116);
  }
}