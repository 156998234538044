.ae-sh-library .sh.btn {
  text-transform: uppercase;
  font-family: $font-family-base-reg;

  &.btn-default {
    border: none;
    background-color: transparent;
    color: var(--primary);

    &:hover, &:focus, &:active {
      background-color: transparent;
    }
  }
}

.custom-dropdown {
  border: none;
  background-color: transparent;
  color: $primary;
  font-family: $font-family-base-reg;

  &:hover {
    color: $primary-hover;
  }

  .custom-dropdown-menu {

  }


}

.navi {
  .navi-item {
    .navi-link {
      padding: 0.50rem 1.0rem;
      cursor: pointer;

      .navi-icon {
        flex: 0 0 1.5rem;
      }
    }
  }
}

.sh.btn.btn-default.dropdown-toggle.btn-primary {
  padding: 12px 20px !important;
  color: var(--primary);

  &:hover, &:focus, &:active {
    background-color: #F3F6F9;
    color: var(--primary-dark);

    &:after {
      color: var(--primary-dark);
    }
  }

  &:after {
    color: var(--primary);
  }
}


.ae-sh-library .btn-primary.btn-login {
  background-color: var(--primary);
  color: var(--white);

}