.product-row {
    .product-col {
        margin-bottom: 20px;

        &:nth-child(odd) {
            padding-right: 20px;
        }

        &:nth-child(even) {
            padding-left: 20px;
        }
    }
}