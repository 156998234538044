@import 'variables';

.shared-button {
  width: $widthButton;
  border-radius: $borderRadiusButton;
  font-family: $fontFamilyButton;
  line-height: normal;

  &.btn-lg {
    height: $heightLargeButton;
    font-size: $fontSizeLargeButton;
  }

  &.btn-sm {
    height: $heightSmallButton;
    font-size: $fontSizeSmallButton;
  }
}