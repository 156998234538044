@import 'variables';

.shared-wizardGroup {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $border-color;
  gap: 16px;
  row-gap: 0px;

  .shared-wizardStep {
    margin-bottom: 12px;


    &:last-child {
      .icon-step {
        .icon {
          display: none;
        }
      }
    }
  }
}