// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
/*@import "~perfect-scrollbar/css/perfect-scrollbar.css";*/

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
/*@import '~@sweetalert2/theme-minimal/minimal.scss';*/

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}

// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

@import './shared';
@import "./app/shared/shared";
@import './libraries/header/sass';
@import './libraries/footer/sass';
@import "./scss";

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;

  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;

}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}


input.selection-input-4 {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  border: 1px solid #3a80bb;
  padding: 8px;
  display: inline-block;
  position: relative
}

input.selection-input-4:checked {
  background-color: #fff;
  border: 1px solid #3a80bb;
  color: #3a80bb;
}

input.selection-input-4:checked:after {
  content: '\00AC';
  transform: rotate(-225deg);
  font-size: 24px;
  position: absolute;
  top: -11px;
  left: 2px;
  color: #3a80bb;
}


.noTextWrap {
  white-space: nowrap;
}

small {
  font-size: 12px;
}

.min-width-100px {
  min-width: 100px;
}

.show > .btn.error.btn-primary.dropdown-toggle {
  background-color: #8b1a25;
}

.show > .btn.danger.btn-primary.dropdown-toggle {
  background-color: #bd5417;
}

.btn {
  &.error {
    background-color: #c00000;
    border: none;

    &.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text),
    .btn.btn-primary:not(:disabled):not(.disabled).active,
    .show > .btn.btn-primary.dropdown-toggle,
    .show .btn.btn-primary.btn-dropdown {
      background-color: #8b1a25;
    }

    &.btn-primary:hover:not(.btn-text) {
      background-color: #8b1a25;
    }
  }

  &.danger {
    background-color: #f05800;
    border: none;

    &.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text),
    .btn.btn-primary:not(:disabled):not(.disabled).active,
    .show > .btn.btn-primary.dropdown-toggle,
    .show .btn.btn-primary.btn-dropdown {
      background-color: #bd5417;
    }

    &.btn-primary:hover:not(.btn-text) {
      background-color: #bd5417;
    }
  }

}

.update-registry-check {
  label[for="ck-updateRegistry"] {
    font-size: 16px;
  }
}


/*pdf prescription*/

#patientPrescriptionPdf {
  font-size: 12px;
  font-family: Arial;
  width: 420px;
  antialiased: true;

  .title-page {
    font-size: 12px !important;;
    font-family: Arial;
    border-bottom: 1px solid #d1e0ed !important;

    &.patient {
      font-size: 10px !important;
      //border-bottom: 1px solid #d1e0ed !important;
      border: none !important;
    }

  }


  .label-value {
    padding-right: 0px;
    min-width: initial;
    margin-bottom: 0px !important;

    .sh-label {
      font-size: 8px;
    }

    .value {
      font-size: 8px;
    }
  }


  .title-product {
    h3 {
      font-size: 10px;
      font-family: Arial;
      margin-bottom: 12px !important;
    }

    .caption {
      margin-top: -6px !important;
      font-size: 8px;
      line-height: initial !important;
    }
  }

  .title-paragraph, .ae-sh-library .title-paragraph {
    font-size: 10px !important;
  }

  .detail-patient {
    margin-top: -12px !important;
    font-size: 12px;
    row-gap: 0px !important;
    column-gap: 12px !important;
    margin-bottom: 12px;

    .info-col {
      row-gap: 8px !important;
      column-gap: 12px !important;
    }

    .address-col {
      row-gap: 8px !important;
      column-gap: 12px !important;
    }


  }

  .template-sg {
    .row.row-gap-4 {
      row-gap: 8px !important;
    }
  }

  .divider {
    border-top: 1px solid #d1e0ed;
  }

  .prod-action {
    display: none;
  }

  .info-product {
    .heading-4, .ae-sh-library h4 {
      margin-top: 12px;
      font-size: 8px !important;
    }

    .tag {
      font-size: 8px !important;
      line-height: auto !important;
    }
  }

  .content-product-item {
    .list-key-value {
      font-size: 8px !important;

      .label-item {
        font-size: 8px !important;
      }
    }
  }
}

.ae-sh-library.sgfe .shared-checkbox input.checkbox-sh {
  height: 0px;
}

