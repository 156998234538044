@import "button";
@import "card";
@import "form";
@import "header";
@import "layout";
@import "modal";
@import "sweetAlert";
@import "piker";
@import "table";
@import "wizard";

html, body, .flex-root {
  background: $white;
  -webkit-font-smoothing: antialiased;
}


.list-key-value {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  row-gap: 4px;

  & > span {
    display: flex;
    flex-direction: column;
  }

  span {
    font-size: 18px;
    color: $primary-dark;
  }

  span.label-item {
    font-size: 14px !important;
    color: $gray-900;
  }
}

/*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 6px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  background-image: -webkit-gradient(linear,
          40% 0%,
          75% 84%,
          from(rgba(19, 84, 145, 0.5)),
          to(rgba(62, 121, 168, 0.5)),
          color-stop(0.6, rgba(19, 84, 145, 0.5)));

}*/

* {
  //scrollbar-color: $primary #fff;
  scrollbar-width: thin;
}
