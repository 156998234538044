$icomoon-font-family: "icon-pack" !default;
$icomoon-font-path: "./fonts-icon/" !default;

$icon-track: "\e933";
$icon-pharmacy: "\e92f";
$icon-user-check: "\e930";
$icon-user-x: "\e931";
$icon-truck: "\e932";
$icon-lock: "\e91a";
$icon-warning: "\e92c";
$icon-error: "\e92d";
$icon-success: "\e92e";
$icon-check-unchecked: "\e90b";
$icon-check-checked: "\e90c";
$icon-more: "\e905";
$icon-moreV: "\e913";
$icon-note: "\e914";
$icon-user-circle: "\e915";
$icon-contact: "\e916";
$icon-user: "\e917";
$icon-attach: "\e918";
$icon-edit: "\e919";
$icon-file: "\e91b";
$icon-folder-check: "\e91c";
$icon-menu: "\e91d";
$icon-cancel_black: "\e91e";
$icon-close: "\e91f";
$icon-remove_circle: "\e920";
$icon-remove: "\e921";
$icon-add: "\e922";
$icon-add_circle: "\e923";
$icon-download_black: "\e924";
$icon-done: "\e925";
$icon-save_alt: "\e926";
$icon-save_black: "\e927";
$icon-exit_to_app: "\e928";
$icon-logout: "\e929";
$icon-published: "\e92a";
$icon-add_task: "\e92b";
$icon-radio-unchecked: "\e903";
$icon-radio-checked: "\e904";
$icon-trash: "\e900";
$icon-store: "\e901";
$icon-search: "\e902";
$icon-list: "\e906";
$icon-chevron-top: "\e907";
$icon-chevron-right: "\e908";
$icon-chevron-left: "\e909";
$icon-chevron-bottom: "\e90a";
$icon-cart: "\e90d";
$icon-arrow-top: "\e90e";
$icon-arrow-right: "\e90f";
$icon-arrow-left: "\e910";
$icon-arrow-bottom: "\e911";
$icon-app: "\e912";

