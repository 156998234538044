@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?l9m3rp');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?l9m3rp#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?l9m3rp') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?l9m3rp') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?l9m3rp##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-track {
  &:before {
    content: $icon-track;     
    color: #005591;
  }
}
.icon-pharmacy {
  &:before {
    content: $icon-pharmacy; 
  }
}
.icon-user-check {
  &:before {
    content: $icon-user-check; 
  }
}
.icon-user-x {
  &:before {
    content: $icon-user-x; 
  }
}
.icon-truck {
  &:before {
    content: $icon-truck; 
  }
}
.icon-lock {
  &:before {
    content: $icon-lock; 
  }
}
.icon-warning {
  &:before {
    content: $icon-warning;     
    color: #faad14;
  }
}
.icon-error {
  &:before {
    content: $icon-error;     
    color: #E1000F;
  }
}
.icon-success {
  &:before {
    content: $icon-success;     
    color: #009B3C;
  }
}
.icon-check-unchecked {
  &:before {
    content: $icon-check-unchecked;     
    color: #005591;
  }
}
.icon-check-checked {
  &:before {
    content: $icon-check-checked;     
    color: #005591;
  }
}
.icon-more {
  &:before {
    content: $icon-more; 
  }
}
.icon-moreV {
  &:before {
    content: $icon-moreV; 
  }
}
.icon-note {
  &:before {
    content: $icon-note; 
  }
}
.icon-user-circle {
  &:before {
    content: $icon-user-circle;     
    color: #3e81b9;
  }
}
.icon-contact {
  &:before {
    content: $icon-contact; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-attach {
  &:before {
    content: $icon-attach;     
    color: #565b6f;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;     
    color: #565b6f;
  }
}
.icon-file {
  &:before {
    content: $icon-file; 
  }
}
.icon-folder-check {
  &:before {
    content: $icon-folder-check; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-cancel_black {
  &:before {
    content: $icon-cancel_black; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-remove_circle {
  &:before {
    content: $icon-remove_circle; 
  }
}
.icon-remove {
  &:before {
    content: $icon-remove; 
  }
}
.icon-add {
  &:before {
    content: $icon-add; 
  }
}
.icon-add_circle {
  &:before {
    content: $icon-add_circle; 
  }
}
.icon-download_black {
  &:before {
    content: $icon-download_black; 
  }
}
.icon-done {
  &:before {
    content: $icon-done; 
  }
}
.icon-save_alt {
  &:before {
    content: $icon-save_alt; 
  }
}
.icon-save_black {
  &:before {
    content: $icon-save_black; 
  }
}
.icon-exit_to_app {
  &:before {
    content: $icon-exit_to_app; 
  }
}
.icon-logout {
  &:before {
    content: $icon-logout; 
  }
}
.icon-published {
  &:before {
    content: $icon-published; 
  }
}
.icon-add_task {
  &:before {
    content: $icon-add_task; 
  }
}
.icon-radio-unchecked {
  &:before {
    content: $icon-radio-unchecked;     
    color: #005591;
  }
}
.icon-radio-checked {
  &:before {
    content: $icon-radio-checked;     
    color: #005591;
  }
}
.icon-trash {
  &:before {
    content: $icon-trash;     
    color: #005591;
  }
}
.icon-store {
  &:before {
    content: $icon-store;     
    color: #00a0e1;
  }
}
.icon-search {
  &:before {
    content: $icon-search;     
    color: #005591;
  }
}
.icon-list {
  &:before {
    content: $icon-list;     
    color: #005591;
  }
}
.icon-chevron-top {
  &:before {
    content: $icon-chevron-top;     
    color: #cbe6f6;
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;     
    color: #7faac8;
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;     
    color: #cbe6f6;
  }
}
.icon-chevron-bottom {
  &:before {
    content: $icon-chevron-bottom;     
    color: #cbe6f6;
  }
}
.icon-cart {
  &:before {
    content: $icon-cart;     
    color: #005591;
  }
}
.icon-arrow-top {
  &:before {
    content: $icon-arrow-top;     
    color: #238fcd;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;     
    color: #238fcd;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;     
    color: #238fcd;
  }
}
.icon-arrow-bottom {
  &:before {
    content: $icon-arrow-bottom;     
    color: #238fcd;
  }
}
.icon-app {
  &:before {
    content: $icon-app;     
    color: #238fcd;
  }
}


