@import 'variables';
.shared-modal {
    & .modal-content{
        border: none;
        & .modal-header{
            border: none;
            & .modal-title{
                font-size: 18px;
                color: $primary;
            }
        }
        & .modal-body{
            font-size: 16px;
        }
        & .modal-footer{
            border: none;
            padding: 24px 42px;
        }
    }

}
