.badge-sh {
  border-radius: 6px;
  line-height: normal;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  white-space: normal;

  &.primary {
    background-color: #006fc977;
  }

  &.success {
    background-color: #24aa02a2;
  }

  &.danger {
    background-color: #e2880098;
  }

  &.error {
    background-color: #e2000098;
  }

  &.muted {
    background-color: #5a5a5a77;
  }

}