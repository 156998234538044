@import "variables";

.sh-iconBtn {
    background-color: none;

    &:hover {
        cursor: pointer;
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.4;
    }
}