@import 'variables';

.custom-select {
    box-shadow: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff no-repeat right 0rem center/28px 28px;
}

.custom-select-multi {
    &.rmsc {
        --rmsc-main: #4285f4;
        --rmsc-hover: #f1f3f5;
        --rmsc-selected: #e2e6ea;
        --rmsc-border: #{$border-color-select};
        --rmsc-gray: #{$color-select};
        --rmsc-bg: #fff;
        --rmsc-p: 10px;
        --rmsc-radius: 0px;
        --rmsc-h: #{$height-select};


        .select-item {
            span {
                font-weight: normal;
            }
        }

        .dropdown-heading-dropdown-arrow {
            transform: scale(0.8);
        }
    }
}