@import 'variables';

.shared-product-card,
.shared-product-cart {
  display: flex;
  padding-bottom: 12px;
  border-bottom: 1px solid #B9CDD7;
  margin-bottom: 12px;
  height: 100%;

  .thumb-info {
    .thumb {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 128px;
      height: 128px;
      background: rgba(195, 206, 214, 0.3);
      color: #005591;
      margin: 0px 12px 12px 0px;
      font-size: 14px;
    }
  }

  .info-product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .head-info-product {
      display: flex;
      justify-content: space-between;
      margin-top: 6px;

      .title-product {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-right: 12px;

        h3 {
          font-size: 18px;
          margin-bottom: 0px;
        }
      }
    }

    .footer-info-product {
      display: flex;
      justify-content: flex-end;
    }
  }

  &.disabled {
    opacity: 0.4;
    //.thumb-info {
    //  .thumb {
    //    background: rgb(196, 196, 196);
    //  }
    //}
  }
}