@import "variables";

.heading-1 {
  font-size: $fontSizeHeading1;
  color: $colorHeading1;
  line-height: $lineHeightHeading1;

  &.reverse{
    color: $colorHeading1Reverse;
  }
}
