.shared-user-avatar {
    background-color: #912600;
    padding: 8px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
    font-weight: bold;
}