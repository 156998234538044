@import 'variables';

.shared-checkbox.sg {
  display: flex;
  align-items: center;

  .input-group-text {
    //visibility: hidden;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 0px;
    display: flex;
    align-items: center;
    background-color: #ffffff00;
    border: none;
  }

  input.checkbox-sh.sg {
    //visibility: hidden;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 22px;
    height: 22px;

    &:before {
      //visibility: visible;
      appearance: auto;
      -webkit-appearance: auto;
      -moz-appearance: auto;
      font-family: $fontFamilyIconCheckbox;
      font-size: $sizeEmpty;
      content: $iconEmptyCheckbox;
      position: absolute;
      color: $colorCheckbox;
      top: 0px;
      left: 0px;
    }

    &:checked {
      &:after {
        //visibility: visible;
        appearance: auto;
        -webkit-appearance: auto;
        -moz-appearance: auto;
        font-family: $fontFamilyIconCheckbox;
        content: $iconCheckCheckbox;
        position: absolute;
        font-size: $sizeCheckbox;
        color: $colorCheckbox;
        top: 0px;
        left: 0px;
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  & label {
    margin-bottom: 0px;
    margin-left: 8px;
    font-weight: normal;
    font-size: 16px;
  }

}
.shared-checkbox.input-group{
  align-items: center!important;
}