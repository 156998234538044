@import 'variables';

.caption {
  font-size: $fontSizeCaption;
  color: $colorCaption;
  line-height: $lineHeightCaption;
  margin: 0px;
  padding: 0px;

  &.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 12px);
  }

  &.primary {
    color: $colorCaptionPrimary;
  }

  &.dark {
    color: #686C82;
  }

  &.red {
    color: $colorCaptionRed;
  }

  &.bold {
    font-weight: normal;
    font-family: $font-family-base-reg;
  }

  &.lg {
    font-size: $fontSizeCaptionLg;
  }

}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 12px);
}