@import 'variables';

.shared-icon {
  display: flex;
  align-items: center;

  &.pointer {
    cursor: pointer;
  }

  & .icon {
    font-size: 18px;
    margin-right: 8px;
    margin-left: 8px;

    &.disabled {
      opacity: 0.4;
    }

    &::before {
      color: var(--color-icon-var);
    }
  }

  .iconLabel {
    line-height: normal;
  }

}