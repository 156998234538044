@import 'variables';

.shared-customer-detail {
    padding: 16px 30px;
    border-bottom: 1px solid #D2E1EB;
    display: flex;
    width: 100%;

    svg {
        color: #E8C451;
        font-size: 18px;

    }
}