@import "./icon/style";

@import "variables";
@import "ui";
@import "design";


.pointer {
  cursor: pointer;
}

.wordNoWrap {
  white-space: nowrap;
}

.row-gap-4 {
  row-gap: 1rem;
}

.label-no-wrap {
  .shared-label {
    white-space: nowrap;
  }
}

@media (min-width: $lg-device) {
  .w-lg-150px {
    min-width: 150px !important;
  }
}

