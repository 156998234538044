@import "variables";

.box-label {
  background: linear-gradient(90deg, #b8ccd680, #b9cdd740);
  border-radius: 1px;
  padding: 20px;
  position: relative;

  .content-box-label {
    max-height: 200px;
    overflow-y: auto;
  }

  .title-box {
    top: -10px;
    position: absolute;
    color: white;
    border-radius: 8px;
    padding: 0px 6px;
    background-color: #f58e53;
  }
}