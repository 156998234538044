@import 'variables';

.shared-toast {
  margin-bottom: 4px;
  border-radius: 0px;

  .toast {
    border-radius: 0px;

    & .toast-header {
      min-height: 40px;
      font-size: 16px;
      background-color: transparent;
      padding: 12px;

      & .close {
        color: #565b6f;
        background: transparent;
        border: none;

        margin-left: auto;
        font-size: 20px;
      }
    }

    & .toast-body {
      padding: 12px 24px;
    }
  }
}